import {
  CardPicasso,
  HeroFull,
  heroFullImageSizes,
  Icon,
} from "@liberetech/design-system";
import Link from "@liberetech/design-system/Link";
import NavLink from "@liberetech/design-system/NavLink";
import Paper from "@liberetech/design-system/Paper/Paper";
import Section from "@liberetech/design-system/Section";
import SectionHeader from "@liberetech/design-system/SectionHeader";
import classNames from "classnames";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import React from "react";

import AppBadgeAndroid from "components/AppBadge/AppBadgeAndroid";
import AppBadgeIOS from "components/AppBadge/AppBadgeIOS";
import CardDaVinci from "components/CardDaVinci/CardDaVinci";
import Carousel from "components/Carousel/Carousel";
import ImageCDN from "components/ImageCDN/ImageCDN";
import SectionSpecialRates from "components/SectionSpecialRates/SectionSpecialRates";
import { BreadcrumbJsonLd, Seo } from "components/Seo/Seo";
import fetchCms from "lib/client/cms";
import { Homepage, homepage } from "lib/data";
import { Trans, useTranslation } from "lib/i18n/client";
import { isAssetPromotionEnabled } from "lib/promotion/promotion";
import { TrackingContextPage } from "lib/tracking";
import withDirectChannelCommonProps from "lib/withDirectChannelCommonProps";
import { DirectChannelCommonProps, Page } from "types";

import styles from "./index.module.css";

const imageBase = "/static/images/home/";

const HomepageView: React.FC<HomePageProps & DirectChannelCommonProps> = ({
  assets,
  destinations,
  lastPosts,
  brand,
  homepage,
}) => {
  const { t } = useTranslation("homepage");
  const { locale } = useRouter();

  return (
    <>
      <Seo
        title={homepage.seo.title}
        description={homepage.seo.metaDesc}
        titleTemplate="%s"
      />
      <HeroFull
        title={homepage.title}
        subtitle={homepage.subtitle}
        claim={
          <>
            <svg viewBox="0 0 24 24">
              <path d="M3.75 12h.75c1.701 0 3.62.935 4.5 2.25h3c1.505 0 3 1.495 3 3H8.25" />
              <path d="M14.52 15.75h4.98c2.719 0 3 1.5 3 1.5l-10.74 4.362a3.22 3.22 0 0 1-2.638-.11L3.75 18.774" />
              <path d="M18 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.75 10.5h-3v9.75h3V10.5ZM9 7.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            </svg>
            <span>
              <Trans
                i18nKey={"common:hero.bestPrice"}
                components={[<strong />]}
              />
            </span>
          </>
        }
        image={
          <ImageCDN
            src={homepage.featuredImage.src}
            alt={brand.slogan}
            sizes={heroFullImageSizes}
          />
        }
      />
      <Section>
        <SectionHeader
          className="grid"
          label={brand.name}
          title={t("destinations.title", { brand: brand.name })}
        />
        <Carousel
          classNames={{
            root: styles.carousel,
            images: styles.carouselImages,
            buttons: styles.carouselButtons,
            button: styles.carouselButton,
          }}
        >
          {brand.enabledFeatures?.includes("destinations")
            ? destinations.map((destination, i) => (
                <NavLink
                  href={destination.uri}
                  className={styles.destination}
                  key={i}
                >
                  <CardDaVinci
                    key={i}
                    title={destination.title}
                    label={
                      destination.assets.length === 0
                        ? t("destinations.comingSoon")
                        : destination.assets.filter((asset) =>
                              isAssetPromotionEnabled(
                                asset.properties.id,
                                brand,
                              ),
                            ).length > 0
                          ? t("common:promo.discount")
                          : undefined
                    }
                    image={
                      <ImageCDN
                        alt={destination.title}
                        src={destination.featuredImage.node.sourceUrl}
                        loading="lazy"
                        sizes="(max-width: 1024px) 400px, 768px"
                      />
                    }
                  />
                </NavLink>
              ))
            : assets.map((asset, i) => (
                <NavLink
                  href={asset.uri}
                  className={styles.destination}
                  key={i}
                >
                  <CardDaVinci
                    key={i}
                    title={asset.title}
                    label={
                      isAssetPromotionEnabled(asset.properties.id, brand)
                        ? t("common:promo.discount")
                        : undefined
                    }
                    image={
                      <ImageCDN
                        alt={asset.title}
                        src={asset.featuredImage.node.sourceUrl}
                        loading="lazy"
                        sizes="(max-width: 1024px) 400px, 768px"
                      />
                    }
                  />
                </NavLink>
              ))}
        </Carousel>
      </Section>
      {homepage.experiences && (
        <Section className={classNames(styles.sectionExperience, "grid")}>
          <div className={classNames(styles.overlappedImages)}>
            <picture>
              <source
                srcSet={`${imageBase}about1_400.webp`}
                type="image/webp"
              />
              <img
                alt=""
                className={styles.overlappedImage}
                src={`${imageBase}about1_400.jpg`}
              />
            </picture>
            <picture>
              <source
                srcSet={`${imageBase}about2_400.webp`}
                type="image/webp"
              />
              <img
                alt=""
                className={classNames(
                  styles.overlappedImage,
                  styles.overlappedImageLast,
                )}
                src={`${imageBase}about2_400.jpg`}
              />
            </picture>
            {homepage.experiences.slogan && (
              <span className={styles.overlappedImagesText}>
                {homepage.experiences.slogan}
              </span>
            )}
          </div>
          <div>
            <SectionHeader
              label={brand.name}
              title={homepage.experiences.title}
            />
            <div className={styles.experiences}>
              {homepage.experiences.features.map((experience, i) => (
                <div key={i} className={styles.experience}>
                  <Icon type="contrast">
                    <div
                      dangerouslySetInnerHTML={{ __html: experience.icon }}
                    />
                  </Icon>
                  <h3 className={styles.experienceTitle}>{experience.title}</h3>
                  <p>{experience.description}</p>
                </div>
              ))}
            </div>
          </div>
        </Section>
      )}
      {homepage.technology && (
        <Section background="contrast">
          <div className={classNames(styles.sectionTechnology, "grid")}>
            <div className={styles.technologyContent}>
              <SectionHeader
                label={homepage.technology.label}
                title={homepage.technology.title}
                description={
                  <>
                    <p>{homepage.technology.description}</p>
                    {brand.app?.store && (
                      <>
                        <p>
                          <strong>{t("technology.downloadApp")}</strong>
                        </p>
                        <div className={styles.technologyAppBadges}>
                          <a
                            aria-label="App Store"
                            href={brand.app.store.ios}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            onClick={() => {
                              window.dataLayer.push({
                                event: "click",
                                event_category: "native_app_download",
                                event_label: "ios",
                              });
                            }}
                          >
                            <AppBadgeIOS />
                          </a>
                          <a
                            aria-label="Google Play"
                            href={brand.app.store.android}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            onClick={() => {
                              window.dataLayer.push({
                                event: "click",
                                event_category: "native_app_download",
                                event_label: "android",
                              });
                            }}
                          >
                            <AppBadgeAndroid />
                          </a>
                        </div>
                      </>
                    )}
                  </>
                }
              />
            </div>
            <div className={styles.technologyInfography}>
              <img alt="" src={`${imageBase}tech.png`} loading="lazy" />
              <ul className={styles.technologyFeatures}>
                {[IconHotelBell, IconBreakfast, IconTemple, IconHeadset].map(
                  (FeatureIcon, i) => (
                    <li key={i}>
                      <Paper className={styles.technologyFeature} elevation={1}>
                        <Icon
                          className={styles.technologyFeatureIcon}
                          type="contrast"
                          rounded
                        >
                          <FeatureIcon />
                        </Icon>
                        {t(`technology.features.${i}`)}
                      </Paper>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </Section>
      )}
      {lastPosts.length > 0 && (
        <Section className="grid">
          <SectionHeader
            label={brand.name}
            title={t("lastPosts.title")}
            centered
          />
          <ul className={classNames(styles.lastPosts, "grid")}>
            {lastPosts.map((post, i) => (
              <li className={styles.post} key={i}>
                <NavLink href={post.uri}>
                  <CardPicasso
                    elevation={1}
                    key={i}
                    title={post.title}
                    image={
                      <ImageCDN
                        alt={post.title}
                        src={post.featuredImage.node.sourceUrl}
                        sizes="400px"
                        loading="lazy"
                      />
                    }
                  />
                </NavLink>
              </li>
            ))}
          </ul>
          <NavLink className={styles.lastPostsShowAll} href="/blog">
            <Link>{t("lastPosts.showAll")}</Link>
          </NavLink>
        </Section>
      )}
      {brand.id === "libere" && (
        <SectionSpecialRates
          label={t("segments.label", { brand: brand.name })}
          title={t("segments.title")}
        />
      )}
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: t("common:breadcrumbs.home"),
            item: `https://${brand.currentHost}/${locale}`,
          },
        ]}
      />
    </>
  );
};

const IconHotelBell = () => (
  <svg viewBox="0 0 32 32">
    <path d="M16 7c-7 3-9 8.7-9 14v4M16 7V3" />
    <path d="M30 21c0-7.7-6.3-14-14-14S2 13.3 2 21v4h28v-4zM2 29h28M21 3H11" />
  </svg>
);

const IconTemple = () => (
  <svg viewBox="0 0 25 24">
    <path d="M3.75 12v11.25M21.75 12v11.25M17.25 12v11.25M12.75 12v11.25M8.25 12v11.25" />
    <path d="M12.75.75v3M21 12a8.23 8.23 0 00-8.25-8.25A8.23 8.23 0 004.5 12M2.25 23.25h21M2.25 12h21" />
  </svg>
);

const IconHeadset = () => (
  <svg viewBox="0 0 32 32">
    <path d="M19 31h6a4 4 0 004-4v-4M9 16H3v7a3 3 0 003 3h3V16z" />
    <path d="M29 16h-6v10h3a3 3 0 003-3v-7zM29 16v-2a13 13 0 10-26 0v2" />
  </svg>
);

const IconBreakfast = () => (
  <svg viewBox="0 0 25 24">
    <path d="M11.25.75v3M7.5 2.25v3M15 2.25v3" />
    <path d="M20.25 9H24v3.75c0 .82-.68 1.5-1.5 1.5h-2.25" />
    <path d="M11.25 23.25c-4.95 0-9-4.05-9-9V9h18v5.25c0 4.95-4.05 9-9 9z" />
    <path d="M16.5 13.5H12V18h4.5v-4.5zM14.25 9v4.5" />
  </svg>
);

export const getServerSideProps: GetServerSideProps =
  withDirectChannelCommonProps<HomePageProps>(async ({ req, locale }) => {
    const brand = req.context.brand;
    if (!locale || !brand.locales.includes(locale)) {
      return {
        notFound: true,
      };
    }

    const response = await fetchCms(
      `{
        destinations(where: {language: ${locale.toUpperCase()}, orderby: {field: DATE, order: ASC}}, first: 100) {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
            assets {
              properties {
                id
              }
            }
          }
        }
        assets(where: {language: ${locale.toUpperCase()}, orderby: {field: DATE, order: ASC}}) {
          nodes {
            title
            uri
            fullName
            properties {
              id
              closed
            }
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
        posts(where: {language: ${locale.toUpperCase()}}, first: 3) {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }`,
      brand.cmsUrl,
    );

    const destinations = (
      response.destinations as { nodes: Array<DestinationResponse> }
    )?.nodes;
    const assets = (
      response.assets as { nodes: Array<AssetResponse> }
    )?.nodes.filter((asset) => !asset.properties.closed);
    const lastPosts = (response.posts as { nodes: Array<PostResponse> })?.nodes;

    const homepageData = await homepage(brand.id, locale);

    if (!homepageData) {
      return { notFound: true };
    }

    return {
      props: {
        homepage: homepageData,
        trackingContext: {
          page_type: "home",
        },
        languageAlternates: req.context.brand.locales.map((locale) => ({
          href: `/${locale}`,
          hrefLang: locale,
        })),
        destinations,
        assets,
        lastPosts,
        page: {
          searchBar:
            brand.enabledFeatures?.includes("destinations") &&
            destinations.length > 0
              ? {
                  destinations: destinations
                    .map(({ title, uri }) => ({
                      title,
                      uri: `${uri}#assets`,
                    }))
                    .sort((a, b) => (a.title > b.title ? 1 : -1)),
                }
              : {
                  assets: assets
                    .map(({ title, uri }) => ({
                      title,
                      uri,
                    }))
                    .sort((a, b) => (a.title > b.title ? 1 : -1)),
                },
          isRootPage: true,
        },
      },
    };
  });

type HomePageProps = {
  homepage: Homepage;
  destinations: Array<DestinationResponse>;
  assets: Array<AssetResponse>;
  lastPosts: Array<PostResponse>;
  trackingContext: TrackingContextPage;
  page: Page;
};

type DestinationResponse = {
  title: string;
  uri: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
  assets: Array<{ properties: { id: string } }>;
};

type AssetResponse = {
  title: string;
  uri: string;
  fullName: string;
  properties: {
    id: string;
    closed: boolean;
  };
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
};

type PostResponse = {
  title: string;
  uri: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
};

export default HomepageView;
