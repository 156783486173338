import { Brand } from "types";

export const isAssetPromotionEnabled = (
  assetId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  brand: Brand,
): boolean => {
  return [
    "1de5a6ea-1d96-46db-ab77-d031880eeba2", // LBSA
    "41753207-ae70-459b-8ffc-c7130e04d25d", // LVJB
    "242b3676-bb54-47e1-8c1d-73307ec71f75", // LCDR
    "42490f16-14ee-43f3-9b11-90f1c2f3b2f4", // LPYM
    "ba660e94-42a7-4679-abf4-33ebe7108bc8", // LBLV
    "167f2b95-95de-4b29-bbab-5a2ab5a6db39", // LBLD
    "6a76ab2c-d9bd-44e4-944b-c036bf6bacc7", // LBMU
    "d1e4c1d6-14f5-402b-bb0c-4f0aa5b821d6", // LVGA
  ].includes(assetId);
};

export const promotionForAsset = (
  assetId: string,
  brand: Brand,
): Promotion | null =>
  isAssetPromotionEnabled(assetId, brand) ? promotionForBrand(brand) : null;

export const promotionForBrand = (brand: Brand): Promotion | null => {
  if (brand.id === "libere") {
    return {
      discount: { type: "fixed", amount: 15 },
      promoCode: "STAY15",
      startDate: "01/09/2024",
      endDate: "31/10/2024",
      maxNights: 3,
      loyaltyApplies: true,
    };
  }
  return null;
};

export type Promotion = {
  discount: { type: "fixed" | "upTo"; amount: number };
  promoCode: string;
  startDate: string;
  endDate: string;
  maxNights: number;
  translateKey?: string;
  loyaltyApplies?: boolean;
};
